import useDayjs from "@/composables/useDayjs";
import axios from "@/lib/axios";
import { clone, resetObjectRecursive } from "@/lib/utils";
import { useAuthStore } from "@/store/auth";
import { Ticker } from "@dev-team/types/data-provider/models/ticker";
import {
	ModelPermissions,
	PermissionLevel,
} from "@dev-team/types/rest-api/models/Plan";
import { Portfolio } from "@dev-team/types/rest-api/models/Portfolio";
import { Transaction } from "@dev-team/types/rest-api/models/Transaction";
import { ModelName } from "@dev-team/types/rest-api/models/enums/ModelName";
import { GroupConstraintItem } from "@dev-team/types/rest-api/models/schemas/GroupConstraintItem";
import {
	Constraints,
	OptimizationConfig,
} from "@dev-team/types/rest-api/models/schemas/OptimizationConfig";
import { Rebalancing } from "@dev-team/types/rest-api/models/schemas/Rebalancing";
import { WeightConstraintItem } from "@dev-team/types/rest-api/models/schemas/WeightConstraintItem";

import { defineStore } from "pinia";
import { toRef } from "vue";
import { useCurrencyStore } from ".";

type ConfigDefinition = {
	key: string;
	name: string;
};

type ModelDefinition = ConfigDefinition;
type ObjectiveDefinition = ConfigDefinition;
type RiskProfileDefinition = ConfigDefinition & { value: number };
type RiskAversionDefinition = ConfigDefinition & { value: number };
type MetricDefinition = ConfigDefinition;
const PORTFOLIO_METRICS = (): MetricDefinition[] => {
	return [
		{ key: "max_dd", name: "Maximum Drawdown" },
		{ key: "sortino", name: "Sortino Ratio" },
		{ key: "realized_volatility", name: "Volatility" },
		{ key: "value_at_risk", name: "VaR" },
		// { key: "risk_contributors", name: "Risk Contribution of Assets" },
		{ key: "tracking_error", name: "Tracking Error" },
		{ key: "information_ratio", name: "Information Ratio" },
	];
};

const ALL_MODELS = (): ModelDefinition[] => {
	return [
		{ key: "hist", name: "Sample Algorithm" },
		{ key: "ledoit", name: "Linear Method" },
		{ key: "oas", name: "Oracle Method" },
		{ key: "shrunk", name: "Shrunk Model" },
		{ key: "nodewise", name: "Magnus Algorithm" },
		{ key: "fnodewise", name: "Factor Based Magnus Algorithm" },
		{ key: "poet", name: "Approximate Model" },
		{ key: "glasso", name: "Graph Algorithm" },
		{ key: "nonlinear", name: "Nonlinear Method" },
		{ key: "deeplmodel", name: "Dynamic Magnus Algorithm" },
	];
};

const ALL_OBJECTIVES = (): ObjectiveDefinition[] => {
	return [
		{ key: "MinRisk", name: "Minimize Risk" },
		{ key: "MinRisk-Target", name: "Minimize Risk Subject to Target" },
		{ key: "Utility", name: "Maximize Quadratic Utility" },
		{ key: "MaxRet", name: "Maximize Return" },
		{ key: "Sharpe", name: "Maximize Sharpe Ratio" },
	];
};

const RISK_PROFILE_OPTIONS = (): RiskProfileDefinition[] => {
	return [
		{ key: "conservative", name: "Conservative", value: 0.035 },
		{ key: "moderate", name: "Moderate", value: 0.125 },
		{ key: "growth", name: "Growth", value: 0.25 },
		{ key: "aggressive", name: "Aggressive", value: 0.3 },
	];
};

const RISK_AVERSION_OPTIONS = (): RiskAversionDefinition[] => {
	return [
		{ key: "conservative", name: "Conservative", value: 7 },
		{ key: "moderate", name: "Moderate", value: 5 },
		{ key: "growth", name: "Growth", value: 3 },
		{ key: "aggressive", name: "Aggressive", value: 1 },
	];
};
const NODEWISE_MODELS = ["Classic"];
const NODEWISE_CONSTRAINTS = ["short", "noShort"];

interface FormData {
	_id: string | undefined;
	isUpdate: boolean | null;
	isSimulation: boolean;
	name: string;
	description: string;
	plannedInvestment: number;
	transactionCost: number;
	trainPeriod: number;
	startDate: string;
	endDate: string;

	investmentStartDate: string;
	initialAllocation: {
		symbol: string;
		amount: number;
		cost: number;
	}[];
	benchmarks: string[];
	currency: string;
	dataPeriod: number | undefined;
	rebalancing: Rebalancing & { method: string };
	configuration: Omit<OptimizationConfig, "tickers"> & {
		tickers: string[] | Ticker[];
		objective: string | null;
		constraints: {
			groupConstraints: {
				enabled: boolean;
				value: (GroupConstraintItem & { group: string })[];
			};
			weightConstraints: {
				enabled: boolean;
				value: WeightConstraintItem[];
			};
			weightAndGroup: boolean;
			cardinalityConstraints: { enabled: boolean; value: number };
		};
	};
}

const DEFAULT_FORM_DATA = () => {
	const dayjs = useDayjs().value;
	const authStore = useAuthStore();
	const userPlan = toRef(authStore, "userPlan");

	const formData: FormData = {
		_id: undefined,
		isUpdate: null,
		isSimulation: false,
		name: "",
		description: "",
		plannedInvestment: 100000,
		transactionCost: 0.002,
		trainPeriod: 182,
		startDate: dayjs().subtract(1, "year").format("YYYY-MM-DD"),
		endDate: dayjs().format("YYYY-MM-DD"),

		investmentStartDate: dayjs().subtract(160, "days").format("YYYY-MM-DD"),

		initialAllocation: [],
		benchmarks: [],
		currency: authStore.user?.language === "tr" ? "TRY" : "USD",
		dataPeriod: undefined,

		rebalancing: {
			active: true,
			method: "newOptimization",
			threshold: { active: true, value: 0.05 },
			period: { active: false, value: 7 },
			drift: { active: false, value: 0.1 },
			outperform: { active: false, value: { symbols: [], value: 0.15 } },
			jump: { active: false, value: 0.15 },
		},

		configuration: {
			tickers: [],
			models: [],
			objective: "MinRisk",
			sharpe: false,
			targetReturn: null,
			riskProfile: {
				targetRisk: 0.035,
				riskAversion: {
					value: 7,
					trackingError: false,
				},
			},
			constraints: {
				noShort: false,
				short: false,
				maxShort: null,
				weightAndGroup: false,
				weightConstraints: {
					enabled: false,
					value: [],
				},
				groupConstraints: {
					enabled: false,
					value: [],
				},
				views: {
					enabled: false,
					value: [],
				},
				cardinalityConstraints: { enabled: false, value: 1 },
			},
		},
	};

	if (userPlan.value?.details.models) {
		const firstEnabledModel = Object.keys(userPlan.value.details.models).find(
			(key) =>
				userPlan.value?.details.models[key as keyof ModelPermissions] ===
				("enabled" as PermissionLevel),
		);
		if (firstEnabledModel) {
			formData.configuration.models = [firstEnabledModel] as ModelName[];
		}
	}

	if (userPlan.value?.details.constraints.noShortSale === "enabled") {
		formData.configuration.constraints.noShort = true;
	} else if (userPlan.value?.details.constraints.shortSale === "enabled") {
		formData.configuration.constraints.short = true;
	} else if (userPlan.value?.details.constraints.weight === "enabled") {
		formData.configuration.constraints.weightConstraints.enabled = true;
	} else if (userPlan.value?.details.constraints.group === "enabled") {
		formData.configuration.constraints.groupConstraints.enabled = true;
	}

	return formData;
};

const DEFAULT_LIST_COLUMNS: ListColumn[] = [
	{
		name: "statusIcon",
	},
	{
		title: "NAME",
		name: "name",
		sortable: true,
	},
	{
		title: "STATUS",
		name: "status",
	},
	{
		title: "CREATED AT",
		name: "createdAt",
		sortable: true,
	},
	{
		title: "INVESTMENT",
		name: "capital",
		sortable: true,
	},
	{
		title: "BALANCE",
		name: "balance",
		sortable: true,
	},
	{
		name: "actions",
		sortable: false,
	},
];
const DEFAULT_LIST_PARAMS: ListParams = {
	page: 1,
	size: 8,
	search: null,
	sort: "createdAt",
	asc: true,
};

interface IndicatorMetric {
	name: string;
	required_inputs: string[];
	type: string;
}

type IndicatorStatus = "new" | "applied" | "error";

interface AddedIndicator {
	indicator: string;
	key: string;
	status: IndicatorStatus;
	name: string;
	type: string;
	windowSize: number;
}

interface ListColumn {
	name: string;
	sortable?: boolean;
	title?: string;
}

interface ListParams {
	asc: boolean | 0 | 1;
	page: number;
	search: string | null;
	size: number;
	sort: string;
}

interface State {
	formSaving: boolean;
	formData: FormData;

	listLoading: boolean;
	listRecords: Portfolio[];
	listCount: number;
	listColumns: ListColumn[];
	listParams: ListParams;

	detailLoading: boolean;
	detailRecord: Portfolio | null;
	tickersDetail: Ticker[] | null;

	reportLoading: boolean;

	metricError: boolean;

	metrics: { [key: string]: number }[];

	indicatorsAndMetrics: {
		indicators: IndicatorMetric[];
		metrics: IndicatorMetric[];
	};
	addedIndicators: AddedIndicator[];

	ALL_METRICS: ModelDefinition[];
	ALL_MODELS: ModelDefinition[];
	ALL_OBJECTIVES: ObjectiveDefinition[];

	RISK_PROFILE_OPTIONS: RiskProfileDefinition[];
	RISK_AVERSION_OPTIONS: RiskAversionDefinition[];
}
export const usePortfolioStore = defineStore("portfolio", {
	state: (): State => ({
		formSaving: false,
		formData: DEFAULT_FORM_DATA(),

		listLoading: false,
		listRecords: [],
		listCount: 0,
		listColumns: clone(DEFAULT_LIST_COLUMNS),
		listParams: clone(DEFAULT_LIST_PARAMS),

		detailLoading: false,
		detailRecord: null,
		tickersDetail: null,

		reportLoading: false,

		metricError: false,

		indicatorsAndMetrics: {} as {
			indicators: IndicatorMetric[];
			metrics: IndicatorMetric[];
		},

		metrics: [],
		addedIndicators: [],

		ALL_METRICS: PORTFOLIO_METRICS(),
		ALL_MODELS: ALL_MODELS(),
		ALL_OBJECTIVES: ALL_OBJECTIVES(),

		RISK_PROFILE_OPTIONS: RISK_PROFILE_OPTIONS(),
		RISK_AVERSION_OPTIONS: RISK_AVERSION_OPTIONS(),
	}),

	getters: {
		currentWeights: (state) => {
			if (!state.detailRecord || !state.detailRecord.state.assets.length)
				return [];
			const total = state.detailRecord.state.assets.reduce(
				(acc, i) => acc + i.amount * i.price,
				0,
			);
			const weights = state.detailRecord.state.assets.map((i) => ({
				symbol: i.symbol,
				value: (i.price * i.amount) / total,
			}));
			return weights;
		},

		performance: (state) => {
			if (!state.detailRecord) return {};
			return state.detailRecord.history.map((entry) => {
				return {
					date: entry.date,
					balance: entry.state.balance,
					investment:
						entry.state.investment +
						entry.state.credit +
						(entry.state.revenue > 0 ? entry.state.revenue : 0),
				};
			});
		},

		nodewiseAvailability: (state) => {
			const { models, constraints } = state.formData.configuration;

			const allConstraints = [
				"short",
				"noShort",
				"weightConstraints",
				"groupConstraints",
			];

			const selectedConfiguration = allConstraints.find((i) => {
				const val = constraints[i as keyof Constraints];
				if (val && typeof val === "object") {
					return val.enabled;
				}
				return val;
			});

			const available =
				(!models.includes("nodewise") &&
					!models.includes("deeplmodel") &&
					!models.includes("fnodewise")) ||
				(models.every((i) => NODEWISE_MODELS.includes(i)) &&
					NODEWISE_CONSTRAINTS.includes(selectedConfiguration as string));

			return available;
		},

		permission(state) {
			const { userPlan } = useAuthStore();
			const permission: {
				canCreate: boolean;
				reason: string | null;
			} = {
				canCreate: true,
				reason: null,
			};
			if (!userPlan) {
				return permission;
			}
			// portfolios
			if (userPlan.details.portfolios !== "enabled") {
				permission.canCreate = false;
				permission.reason = "You don't have permission to create portfolio";
			}
			// maxPortfolioCount
			if (state.listCount >= userPlan.details.maxPortfolioCount) {
				permission.canCreate = false;
				permission.reason = "You have reached the maximum number of portfolios";
			}
			return permission;
		},

		formIsUpdate(state) {
			return Boolean(state.formData.isUpdate);
		},
	},

	actions: {
		async fetchList() {
			this.listLoading = true;
			try {
				const params = Object.assign({}, DEFAULT_LIST_PARAMS, this.listParams);
				params.asc = Number(params.asc) as 0 | 1;
				const {
					data: { records, count },
				} = await axios.get("/portfolio/list", { params });
				this.listRecords = records;
				this.listCount = count;
				this.listLoading = false;
			} catch (err) {
				this.listLoading = false;
				throw err;
			}
		},

		/**
		 * Fetch details of portfolio
		 * @param {string} id ID of the portfolio
		 * @param {object} options Options
		 * @param {boolean} options.form Save portfolio data to formData instead of detailRecord
		 * @returns Portfolio data
		 */
		async fetchDetail(id: string, { form = false, isSimulation = false } = {}) {
			const currencyStore = useCurrencyStore();
			this.detailLoading = true;
			try {
				let data;
				if (isSimulation) {
					const res = await axios.get(`/simulation/${id}`, {
						params: { withTransactions: true },
					});

					data = res.data;
				} else {
					const res = await axios.get(`/portfolio/${id}`, {
						params: {
							populateTransaction: true,
						},
					});

					data = res.data;
					// don't crash if indicators are not available
					const indicators = await axios
						.get("/indicator")
						.then((res) => res.data)
						.catch(() => ({ data: { indicators: [], metrics: [] } }));
					this.indicatorsAndMetrics = indicators;
				}

				const tickers = await this.getTickers(data.configuration.tickers);
				this.tickersDetail = tickers;

				if (form) {
					this.formData = DEFAULT_FORM_DATA();
					this.$patch({
						formData: {
							...data,
							configuration: {
								...data.configuration,
								tickers: tickers,
								constraints: {
									...data.configuration.constraints,
									weightAndGroup:
										data.configuration.constraints.groupConstraints.enabled ||
										data.configuration.constraints.weightConstraints.enabled,
								},
							},
							isUpdate: true,
							isSimulation: isSimulation,
						},
					});
				} else {
					if (data.currency) {
						await currencyStore.subscribeRatesBySymbols(
							Array.from(
								new Set([
									...data.configuration.tickers,
									...data.state.assets.map((a: Ticker) => a.symbol),
								]),
							),
							{ to: data.currency },
						);
					}
					this.detailRecord = data;
				}
				this.detailLoading = false;
				return data;
			} catch (err) {
				this.detailLoading = false;
				throw err;
			}
		},

		/**
		 * Create new simulation or portfolio, or update existing one
		 * @param {object} options options
		 * @param {boolean} options.rebalanceOnUpdate rebalance portfolio on update
		 * @returns new portfolio/simulation data
		 */
		async save({ rebalanceOnUpdate = false } = {}) {
			this.formSaving = true;
			try {
				const form = clone(this.formData) as FormData;
				form.configuration.tickers = form.configuration.tickers.map(
					(i) => (i as Ticker).symbol,
				);

				//To be compatible for MinRisk with Target return. Actually it is MinRisk objective
				if (form.configuration.objective === "MinRisk-Target")
					form.configuration.objective = "MinRisk";
				// nullify sharpe, targetReturn and riskProfile values by objective field
				// these rules can be moved to backend, or the form page component. for now, it's here.
				const objective = form.configuration.objective;
				if (objective !== "Sharpe") form.configuration.sharpe = false;
				else form.configuration.sharpe = true;
				if (objective !== "MinRisk") form.configuration.targetReturn = null;
				if (objective !== "Utility")
					form.configuration.riskProfile.riskAversion.value = null;
				if (objective !== "MaxRet")
					form.configuration.riskProfile.targetRisk = null;

				let data;

				if (form.isSimulation) {
					const res = await axios.post(
						"/simulation",
						form.isUpdate
							? {
									configuration: form.configuration,
									endDate: form.endDate,
									investmentStartDate: form.investmentStartDate,
									name: form.name,
									plannedInvestment: form.plannedInvestment,
									rebalancing: form.rebalancing,
									startDate: form.startDate,
									trainPeriod: form.trainPeriod,
									transactionCost: form.transactionCost,
									currency: form.currency,
								}
							: form,
					);
					data = res.data;
				} else {
					if (this.formIsUpdate) {
						const res = await axios.post(`/portfolio/${form._id}`, form, {
							params: {
								rebalanceOnUpdate,
							},
						});
						data = res.data;
					} else {
						const res = await axios.post("/portfolio", form);
						data = res.data;
					}
				}
				this.formSaving = false;
				return data;
			} catch (error) {
				this.formSaving = false;
				throw error;
			}
		},

		async getTickers(symbols: string[]): Promise<Ticker[]> {
			let tickerList: Ticker[] = [];
			const {
				data: {
					list: tickers,
					meta: { totalPage },
				},
			} = await axios.post<{
				list: Ticker[];
				meta: { totalSize: number; totalPage: number };
			}>("/dataprovider/tickers", {
				symbols: symbols,
				size: symbols.length,
				page: 1,
			});

			tickerList = [...tickers];

			if (totalPage > 1) {
				for (let i = 2; i <= totalPage; i++) {
					const {
						data: { list: tickersFromPagination },
					} = await axios.post<{ list: Ticker[] }>("/dataprovider/tickers", {
						symbols: symbols,
						size: symbols.length,
						page: i,
					});
					tickerList = [...tickerList, ...tickersFromPagination];
				}
			}

			return tickerList;
		},

		async deleteRecord(id: string) {
			await axios.delete(`/portfolio/${id}`);
		},
		async deletePendingOptimization(id: string) {
			await axios.delete(`/portfolio/pendingOptimization/${id}`);
		},
		async calculate(id: string) {
			// set list record as calculating
			const item = this.listRecords.find((item) => item.id === id);
			if (item) {
				item.status = "calculating";
				item.rebalanceSuggestionNotified = false;
			}
			// set detail record as calculating
			if (this.detailRecord && this.detailRecord.id === id) {
				this.detailRecord.status = "calculating";
				this.detailRecord.rebalanceSuggestionNotified = false;
			}

			await axios.put(`/portfolio/${id}/calculate`);
		},

		async adjustToOriginalWeights(id: string) {
			// set list record as calculating
			const item = this.listRecords.find((item) => item.id === id);
			if (item) {
				item.status = "calculating";
				item.rebalanceSuggestionNotified = false;
			}
			// set detail record as calculating
			if (this.detailRecord && this.detailRecord.id === id) {
				this.detailRecord.status = "calculating";
				this.detailRecord.rebalanceSuggestionNotified = false;
			}

			const transaction = await axios.put(
				`/portfolio/${id}/adjust-to-original-weights`,
			);
			await this.fetchDetail(id);
			return transaction;
		},

		async calculateTransactionAmounts() {
			const { id } = this.detailRecord as Portfolio;
			const { data } = await axios.get(
				`/portfolio/${id}/calculate-transaction-amounts`,
			);
			return data;
		},

		async makeTransaction(transactions: {
			symbol: string;
			amount: number;
			price: number;
		}) {
			const { id } = this.detailRecord as Portfolio;
			const { data } = await axios.put(`/portfolio/${id}/make-transaction/`, {
				transactions,
			});
			return data;
		},

		async completeTransaction(transactions: {
			symbol: string;
			amount: number;
			price: number;
		}) {
			const { id } = this.detailRecord as Portfolio;
			const { data } = await axios.put(
				`/portfolio/${id}/complete-transaction/`,
				{ transactions },
			);
			return data;
		},

		async cancelTransaction(symbols: string[]) {
			const { id } = this.detailRecord as Portfolio;
			const { data } = await axios.put(`/portfolio/${id}/cancel-transaction/`, {
				symbols,
			});
			return data;
		},

		async updateBenchmarks(benchmarks: string[]) {
			const { id } = this.detailRecord as Portfolio;
			const { data } = await axios.post(`/portfolio/${id}/benchmarks`, {
				benchmarks,
			});
			return data;
		},

		async generatePortfolioReport() {
			this.reportLoading = true;

			try {
				const { data } = await axios.get(
					`/portfolio/report-generate/${this.detailRecord?.id}`,
				);
				this.reportLoading = false;

				return data;
			} catch (err) {
				this.reportLoading = false;
				throw err;
			}
		},
		async getMetrics() {
			try {
				const { data } = await axios.get(
					`/portfolio/${this.detailRecord.id}/metrics`,
				);
				this.metrics = data.metrics;
			} catch (err) {
				this.metricError = true;
			}
		},

		RESET_FORM_STATE() {
			resetObjectRecursive(this.formData, DEFAULT_FORM_DATA());
		},
		RESET_LIST_PARAMS() {
			resetObjectRecursive(this.listParams, DEFAULT_LIST_PARAMS);
		},

		SOCKET_updatePortfolio(portfolio: Portfolio) {
			// update list items:
			for (let i = 0; i < this.listRecords.length; i++) {
				if (this.listRecords[i].id === portfolio.id) {
					Object.assign(this.listRecords[i], portfolio);
					break;
				}
			}
			// update detail item:
			if (this.detailRecord && this.detailRecord.id === portfolio.id) {
				Object.assign(this.detailRecord, portfolio);
			}
		},

		SOCKET_transactionCompleted(transaction: Transaction) {
			if (
				this.detailRecord &&
				transaction.portfolio.toString() === this.detailRecord.id
			) {
				this.fetchDetail(this.detailRecord.id);
			}
		},
	},
});
