const routes = [
	{
		path: "/test-error",
		component: () => import("@/components/error-test.vue"),
	},
	{
		name: "AuthLayout",
		path: "/auth",
		component: () => import("@/layouts/auth-layout.vue"),
		redirect: "login",
		children: [
			{
				name: "Login",
				path: "login",
				component: () => import("@/pages/login/login.vue"),
			},
			{
				name: "ForgotPassword",
				path: "forgot-password",
				component: () => import("@/pages/login/forgot-password.vue"),
			},
			{
				name: "RecoverPassword",
				path: "recover-password",
				component: () => import("@/pages/login/recover-password.vue"),
			},
			{
				name: "SubscriptionExpired",
				path: "subscription-expired",
				component: () => import("@/pages/subscription-expired.vue"),
			},
		],
	},
	{
		name: "SSO",
		path: "/sso",
		component: () => import("@/layouts/guest-user-layout.vue"),
		children: [
			{
				name: "Matriks",
				path: "matriks",
				component: () => import("@/pages/sso-login/index.vue"),
			},
			{
				name: "IdealData",
				path: "ideal-data",
				component: () => import("@/pages/sso-login/index.vue"),
			},
		],
	},
	{
		name: "DashboardLayout",
		path: "/",
		redirect: "/portfolio/list",
		component: () => import("@/layouts/dashboard-layout.vue"),
		meta: { auth: "authed" },
		children: [
			{
				name: "PortfolioList",
				path: "portfolio/list",
				component: () => import("@/pages/portfolio-list/index.vue"),
			},
			{
				name: "PortfolioForm",
				path: "portfolio/form/:id?",
				component: () => import("@/pages/portfolio-form/index.vue"),
			},
			{
				name: "PortfolioApproval",
				path: "portfolio/approval/:_id",
				component: () => import("@/pages/portfolio-approval/index.vue"),
			},
			{
				name: "PortfolioDetail",
				path: "portfolio/:_id",
				component: () => import("@/pages/portfolio-detail/index.vue"),
			},
			{
				name: "SimulationForm",
				path: "simulation/form/:id?",
				component: () => import("@/pages/portfolio-form/index.vue"),
				props: {
					simulation: true,
				},
			},
			{
				name: "SimulationList",
				path: "simulation/list",
				component: () => import("@/pages/simulation-list/index.vue"),
			},
			{
				name: "SimulationDetail",
				path: "simulation/:_id",
				component: () => import("@/pages/simulation-detail/index.vue"),
			},
			{
				path: "forecast",
				children: [
					{
						name: "Forecast",
						path: "",
						component: () => import("@/pages/forecast/index.vue"),
					},
					{
						name: "ForecastTicker",
						path: ":symbol",
						component: () => import("@/pages/forecast/ticker-forecast.vue"),
					},
				],
			},
			{
				name: "ChatBot",
				path: "chatbot",
				component: () => import("@/pages/chatbot/index.vue"),
			},
			{
				name: "Administration",
				path: "yonetim",
				component: () => import("@/pages/administration/index.vue"),
			},
			{
				name: "AccountSettings",
				path: "account",
				component: () => import("@/pages/account-settings/index.vue"),
			},
			{
				name: "Statistics",
				path: "statistics",
				component: () => import("@/pages/statistics/index.vue"),
			},

			{
				name: "NotFoundDashboard",
				path: "/:catchAll(.*)",
				redirect: "/portfolio/list",
			},
		],
	},
	{
		name: "NotFound",
		path: "/:catchAll(.*)",
		redirect: "login",
	},
];

export default routes;
